import { useRpQuery } from '~/composables/graphql'
import { useMutation } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import type {
    SignDocumentInput,
    UpdateContractInput
} from '~/resources/graphql/graphql'
import { useActiveFacility } from '~/composables/use-active-facility'

export function useCreateCheckout() {
    const query = useRpQuery()
    return useMutation({
        mutationFn: (facilityId: string) =>
            query(
                graphql(/** GraphQL */ `
                    mutation CreateCheckout($facilityId: ID!) {
                        createCheckout(facilityId: $facilityId) {
                            __typename
                            ... on CreateCheckoutResult {
                                recordId
                            }
                            ... on CreateCheckoutIncomplete {
                                count
                                results
                            }
                        }
                    }
                `),
                {
                    facilityId
                }
            )
    })
}

// Update the contract and create a checkout
export function useUpdateContractCheckoutNowMutation() {
    const query = useRpQuery()
    const activeFacility = useActiveFacility()

    return useMutation({
        mutationFn: (mutationData: UpdateContractInput) =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation UpdateContractCheckoutNow(
                        $input: UpdateContractInput!
                        $facilityId: ID!
                    ) {
                        updateContract(input: $input) {
                            __typename
                            ... on UpdateContractResult {
                                recordId
                            }
                            ... on ValidationError {
                                fieldErrors {
                                    message
                                }
                            }
                        }
                        createCheckout(facilityId: $facilityId) {
                            __typename
                            ... on CreateCheckoutResult {
                                recordId
                            }
                            ... on CreateCheckoutIncomplete {
                                count
                            }
                        }
                    }
                `),
                {
                    input: mutationData,
                    facilityId: activeFacility.value.id
                }
            )
    })
}

// Sign the document on a contract and create a checkout
export function useSignDocumentCheckoutNowMutation() {
    const query = useRpQuery()
    return useMutation({
        mutationFn: (mutationData: {
            input: SignDocumentInput
            facilityId: string
        }) =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation SignDocumentCheckoutNow(
                        $input: SignDocumentInput!
                        $facilityId: ID!
                    ) {
                        signDocument(input: $input) {
                            __typename
                            ... on SignDocumentResult {
                                recordId
                            }
                            ... on ValidationError {
                                fieldErrors {
                                    message
                                }
                            }
                        }
                        createCheckout(facilityId: $facilityId) {
                            __typename
                            ... on CreateCheckoutResult {
                                recordId
                            }
                            ... on CreateCheckoutIncomplete {
                                count
                            }
                        }
                    }
                `),
                {
                    input: mutationData.input,
                    facilityId: mutationData.facilityId
                }
            )
    })
}
